.link
  a
    font-family: StyreneB-Light
    font-weight: 300
    font-size: 12px
    line-height: 24px
    text-transform: uppercase
  &.main
    a
      font-family: StyreneB-Bold
