@import "./constants"

.product-preview
  display: flex
  font-family: StyreneB-Light
  font-weight: 300
  font-size: 14px
  align-items: flex-start
  justify-content: center
  .content
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    height: 100%
    width: 100%
    flex: 3
    .link-container
      width: 100%
  .preview-text
    display: none
    flex: 2
    color: #F6F1ED
    font-size: 56px
    font-family: StyreneB-Bold
    line-height: 50px
    margin-left: 20px
    align-items: center
    max-height: 635px
  &.first
    @media all and (min-width: $desktop-width-breakpoint)
      .preview-text
        display: flex
  a
    font-family: StyreneB-Light
  .image
    margin-bottom: 15px
    position: relative
    padding-bottom: 100%
    @media all and (min-width: $desktop-width-breakpoint)
      margin-bottom: 16px
    & > picture img
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      object-fit: cover
    .hover-block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: none
      justify-content: center
      align-items: center
      background: rgba(157, 172, 228, 0.75)
      .description
        font-family: Noe-Text
        font-size: 14px
        line-height: 16px
        text-align: center
        color: #000000
    .skin-type
      position: absolute
      right: 4px
      bottom: 4px
      display: flex
      align-items: flex-end
      @media all and (min-width: $desktop-width-breakpoint)
        right: 12px
        bottom: 12px
      .name
        font-size: 12px
        font-family: StyreneB-Light
        margin-right: 6px
        @media all and (min-width: $desktop-width-breakpoint)
          font-size: 16px
          margin-right: 10px
      .icon
        max-width: 22px
        width: 100%
        @media all and (min-width: $desktop-width-breakpoint)
          max-width: 35px
        img
          width: 100%
    .sticker
      position: absolute
      top: 6%
      left: 6%
      color: white
      font-size: 4vw
      padding: 1.2% 3.4% 1.5%
      font-family: StyreneB-Light
      background: #9DACE4
      @media all and (min-width: 1000px)
        font-size: 34px
    @media all and (min-width: $desktop-width-breakpoint)
      &:hover
        .hover-block
          display: flex
        .skin-type
          display: none
  .desktop-row
    display: flex
    flex-direction: column
    width: 100%
    align-items: flex-start
    margin-bottom: 4px
    @media all and (min-width: $desktop-width-breakpoint)
      flex-direction: row
      justify-content: space-between
    .mobile
      @media all and (min-width: $desktop-width-breakpoint)
        display: none
    .desktop
      @media all and (max-width: $desktop-width-breakpoint - 1px)
        display: none
    .column
      display: flex
      flex-direction: column
      width: 100%
      flex: 1
      overflow: hidden
      @media all and (min-width: $desktop-width-breakpoint)
        margin-right: 13px
      .name
        text-transform: uppercase
        font-size: 15px
        line-height: 16px
        margin-bottom: 6px
        @media all and (min-width: $desktop-width-breakpoint)
          font-size: 14px
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
      & > .description
        width: 100%
        font-size: 12px
        line-height: 14px
        margin-top: 6px
        @media all and (min-width: $desktop-width-breakpoint)
          margin-top: 0
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
      .price-container
        display: flex
        flex-direction: row
        .discount-percentage
          font-family: StyreneB-Bold
          flex: 1
          text-align: right
          @media all and (min-width: $desktop-width-breakpoint)
            text-align: left
  .button
    z-index: 1
    margin-top: 12px
