@import '../variables'

.tooltip
  position: absolute
  right: 0
  max-width: 70%
  bottom: 0
  margin-bottom: 46px
  margin-right: 5px
  box-sizing: border-box
  display: flex
  flex-direction: column
  .children
    margin-left: auto
    padding: 9px 20px
    box-sizing: inherit
    font-family: $font-bold
    font-size: 12px
    line-height: 16px
    &.error
      background-color: $tapestry-color
      color: $white-color
    &.info
      background-color: $hawkes-blue-color
      color: $black-color
  .arrow
    box-sizing: inherit
    &.error
      &:after
        content: ""
        width: 0
        height: 0
        float: right
        border-style: solid
        border-width: 0 10px 10px 0
        border-color: transparent $tapestry-color transparent transparent
    &.info
      &:after
        content: ""
        width: 0
        height: 0
        float: right
        border-style: solid
        border-width: 0 10px 10px 0
        border-color: transparent $hawkes-blue-color transparent transparent
