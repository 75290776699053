.join-waitlist
  display: flex
  flex-direction: column
  .description
    font-family: Noe-Text
    font-size: 13px
    line-height: 18px
    margin-bottom: 10px
  .form
    display: flex
    .input
      flex: 1
    .button
      height: 38px
      border: 0
      max-width: 150px
      z-index: 1
