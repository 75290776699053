@import "../constants"

.rating
  display: flex
  align-items: center
  height: 20px
  .rating-value
    color: $dull-lavender-color
    font-family: StyreneB-Bold
    font-size: 12px
    line-height: 14px
    margin-right: 6px
  @media all and (min-width: $desktop-width-breakpoint)
    flex-direction: row
    align-items: center
    a:last-of-type
      flex: 1
    .rating
      flex: 1
      display: flex
      justify-content: flex-end
      align-items: center
