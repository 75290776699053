@import "../constants"

.price
  display: flex
  @media all and (min-width: $desktop-width-breakpoint)
    flex-direction: column-reverse
    align-items: flex-end
  .full-price
    text-decoration: line-through
    margin-right: 6px
    @media all and (min-width: $desktop-width-breakpoint)
      margin-right: 0
      margin-top: 4px
  .total
    font-family: StyreneB-Bold
