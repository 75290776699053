@import '../variables'

a.button
  text-decoration: none
  box-sizing: border-box
.button
  text-align: center
  font-size: 18px
  font-family: StyreneB-Light
  font-weight: 300
  line-height: 22px
  outline: none
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  margin: 0
  padding: 0
  transition: all 0.3s
  .loader
    border-radius: 100%
    animation: spin 1s linear infinite
    @keyframes spin
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)
  &.small
    font-size: 12px
    height: 40px
    .loader
      width: 20px
      height: 20px
  &.normal
    font-size: 18px
    height: 48px
    .loader
      width: 26px
      height: 26px
  &:disabled
    opacity: 0.5

  .icon:first-of-type
    margin-right: 8px

.black, a.black
  background: black
  color: white
  border: 1px solid black
  .loader
    border: 2px solid white
    border-top: 2px solid black
  &:focus
    box-shadow: 0 0 6px 2px $dull-lavender-color

.white, a.white
  background-color: white
  color: black
  border: 1px solid white
  margin: 0
  padding: 0
  transition: all 0.3s
  .loader
    border: 2px solid black
    border-top: 2px solid white
  &.loading, &:hover
    background-color: white
  &:focus
    box-shadow: 0 0 6px 2px $starship-color
  &:active
    box-shadow: 0 0 4px 0 $starship-color

.blue, a.blue
  background-color: $dull-lavender-color
  color: black
  border: 1px solid $dull-lavender-color
  .loader
    border: 2px solid black
    border-top: 2px solid $polo-blue-color
  &.loading, &:hover
    background-color: $polo-blue-color
    border-color: $polo-blue-color
  &:focus
    box-shadow: 0 0 6px 2px $starship-color
  &:active
    box-shadow: 0 0 4px 0 $starship-color

.transparent-blue, a.transparent-blue
  background-color: transparent
  color: black
  border: 1px solid $dull-lavender-color
  .loader
    border: 2px solid black
    border-top: 2px solid $dull-lavender-color
  &.loading
    background-color: $dull-lavender-color
  &:focus
    box-shadow: 0 0 6px 2px $starship-color
  &:hover
    background-color: $dull-lavender-color
  &:active
    box-shadow: 0 0 4px 0 $starship-color

.transparent-white, a.transparent-white
  background-color: transparent
  color: black
  border: 1px solid #ffffff
  .loader
    border: 2px solid black
    border-top: 2px solid #ffffff
  &.loading
    background-color: #ffffff
  &:focus
    box-shadow: 0 0 6px 2px $starship-color
  &:hover
    background-color: #ffffff
  &:active
    box-shadow: 0 0 4px 0 $starship-color

.yellow, a.yellow
  color: black
  background-color: $starship-color
  border: none
  .loader
    border: 2px solid black
    border-top: 2px solid $bird-flower-color
  &.loading, &:hover
    background-color: $bird-flower-color
  &:focus
    box-shadow: 0 0 6px 2px $dull-lavender-color
  &:active
    box-shadow: 0 0 4px 0 $dull-lavender-color

.green, a.green
  color: white
  background-color: $sprout-light-color
  border: none
  .loader
    border: 2px solid white
    border-top: 2px solid $sprout-dark-color
  &.loading, &:hover
    background-color: $sprout-dark-color
  &:focus
    box-shadow: 0 0 6px 2px $dull-lavender-color
  &:active
    box-shadow: 0 0 4px 0 $dull-lavender-color

.rose, a.rose
  color: white
  background-color: $sea-pink-color
  border: none
  .loader
    border: 2px solid white
    border-top: 2px solid $sea-pink-color
  &.loading, &:hover
    background-color: $sea-pink-color
  &:focus
    box-shadow: 0 0 6px 2px $dull-lavender-color
  &:active
    box-shadow: 0 0 4px 0 $dull-lavender-color

.transparent-green, a.transparent-green
  background-color: transparent
  color: black
  border: 1px solid $sprout-light-color
  .loader
    border: 2px solid black
    border-top: 2px solid $sprout-light-color
  &.loading
    background-color: $sprout-light-color
  &:focus
    box-shadow: 0 0 6px 2px $starship-color
  &:hover
    background-color: $sprout-light-color
  &:active
    box-shadow: 0 0 4px 0 $starship-color
