// Font
$font-normal: StyreneB-Light, Helvetica, sans-serif
$font-light: StyreneB-Light, Helvetica, sans-serif
$font-bold: StyreneB-Bold, Helvetica, sans-serif
$light: 300
$normal: 300
$base-font-size: 16px

// Colors
$black-color: #000000
$tuna-color: #383b41
$tapestry-color: #AF6385
$starship-color: #ECE83A
$bird-flower-color: #D6D215
$sprout-light-color: #CFDEB7
$sprout-dark-color: #B5CDA2
$dull-lavender-color: #9DACE4
$hawkes-blue-color: #C6D4FB
$polo-blue-color: #8291C9
$white-color: #ffffff
$gallery-color: #ebebeb
$spring-wood-color: #F6F1ED
$pampas-color: #F8F6F3
$ghost-color: #c1c7d0
$gull-gray-color: #B8B8B8
$sea-pink-color: #f09491

$product-image-width: 88%
