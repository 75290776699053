$desktop-width-breakpoint: 800px

.best-sellers
  display: flex
  flex-direction: column
  background: #ECE83A
  padding: 0 20px
  padding-top: 39px
  @media all and (min-width: $desktop-width-breakpoint)
    padding-top: 50px
    padding-bottom: 60px
  .content
    width: 450px
    margin: 0 auto
    max-width: 100%
    @media all and (min-width: $desktop-width-breakpoint)
      width: 1012px
    .title
      font-family: StyreneB-Bold
      font-size: 20px
      @media all and (min-width: $desktop-width-breakpoint)
        margin-left: 88px
    .products
      margin-top: 25px
      display: flex
      flex-direction: column
      @media all and (min-width: $desktop-width-breakpoint)
        flex-direction: row
        align-items: flex-end
      .product-preview
        margin-bottom: 43px
        @media all and (min-width: $desktop-width-breakpoint)
          margin: 0
          margin-left: 3.48vw
          flex: 1
          max-width: 250px
          @media all and (min-width: 1440px)
            min-width: 250px
          &:first-of-type
            margin: 0
            flex: 2
            max-width: 407px
