$star-width: 11px

.rating
  display: flex
  .star
    position: relative
    width: $star-width
    margin-right: 3px
    height: 11px
    svg
      width: $star-width
      height: $star-width
      position: absolute
    .normal
      width: 100%
      height: 100%
      overflow: hidden
      position: absolute
      @for $i from 1 through 9
        &.__#{$i}
          width: #{$i * 10%}
    .empty
      position: absolute
      top: 0
      left: 0
