@import "./constants"

.call-to-action
  display: flex
  flex-direction: column
  margin: 0 auto
  margin-bottom: 40px
  max-width: 1350px
  width: 100%
  @media all and (min-width: $desktop-width-breakpoint)
    margin-bottom: 60px
    margin-top: 56px
    flex-direction: row
    & > *
      flex: 1
  .block
    height: 100px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    overflow: hidden
    @media all and (min-width: $desktop-width-breakpoint)
      height: 130px
    .title
      font-family: StyreneB-Bold
      font-size: 18px
      line-height: 22px
      text-align: center
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 20px
    .description
      font-family: Noe-Text
      font-size: 12px
      line-height: 17px
      text-align: center
      margin-top: 1px
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 14px
        line-height: 16px
    .hover
      position: absolute
      top: 100%
      left: 0
      width: 100%
      height: 100%
      transition: top 0.5s
      img
        width: 100%
        height: 100%
        object-fit: cover
    &:hover
      .hover
        top: 0
    &.submit-idea
      background: #ECE83A
    &.vote
      background: #9DACE4
    &.shop
      background: #F8F6F3
