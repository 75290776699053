@import '../variables'
$font-size-label: 11px

.inputContainer
  display: flex
  flex-direction: column
  width: 100%
  box-sizing: border-box
  font-family: $font-bold
  font-size: $font-size-label
  line-height: 14px
  position: relative
  .tooltipMargin
    margin-bottom: 38px
  .descriptionTooltipMargin
    margin-bottom: 52px !important
  .input
    display: flex
    padding: 6px 10px 6px
    border: 1px solid $ghost-color
    background-color: $white-color
    font-family: $font-normal
    font-weight: $normal
    width: inherit
    box-sizing: inherit
    &::before
      content: attr(data-prefix)
      font-family: $font-light
      font-weight: $normal
      align-self: center
      font-size: $base-font-size
    &.disabled
      opacity: 0.5
      cursor: initial
    &.focused
      border: 1px solid $tuna-color
    &.active
      border: 1px solid $tuna-color
      box-shadow: inset 0 0 4px 0 $tapestry-color
    &.error
      border: 1px solid $tapestry-color
    & > input
      width: 100%
      padding: 0
      border: none
      background-color: inherit
      font-family: $font-light
      font-size: $base-font-size
      font-weight: $light
      line-height: 24px
      color: $tuna-color
      margin: 0
      &::placeholder
        color: $gull-gray-color
      &:focus
        outline: none
    & > button
      cursor: pointer
      border: none
      background: none
      margin-right: -8px
      &:focus
        outline: none
  .description
    margin: 0
    color: $gull-gray-color
