@import '../../javascripts/ui_kit/variables.sass'
$desktop-width-breakpoint: 800px
.not-found-page
  font-family: $font-normal
  .banner
    display: flex
    width: 100%
    height: 300px
    background-color: #9DACE4
    margin-bottom: 40px
    @media all and (min-width: $desktop-width-breakpoint)
      height: 440px
    .left-part
      display: none
      background-color: #8291C9
      .text
        cursor: default
        left: -60px
        letter-spacing: -18px
        position: absolute
        font-size: 350px
        font-family: $font-bold
        line-height: 420px
        color: #9DACE4
      @media all and (min-width: $desktop-width-breakpoint)
        display: flex
        flex: 5 1
        width: 100%
        margin: 0px
    .right-part
      width: 100%
      z-index: 1
      @media all and (min-width: $desktop-width-breakpoint)
        flex: 4 1
        margin: 0px
      .background-text
        cursor: default
        right: 0px
        top: 84px
        letter-spacing: -4px
        position: absolute
        font-size: 150px
        font-family: $font-bold
        line-height: 85px
        color: #8291C9
        @media all and (min-width: $desktop-width-breakpoint)
          display: none
      .content
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 100%
        height: 100%
        padding: 59px 19px 70px 21px
        color: #FFFFFF
        cursor: default
        align-items: flex-start
        .heading
          font-family: $font-bold
          font-size: 24px
          line-height: 24px
          color: black
          z-index: 2
        .text
          width: 232px
          font-size: 20px
          font-family: $font-bold
          line-height: 24px
          z-index: 2
          @media all and (min-width: $desktop-width-breakpoint)
            line-height: 32px
            font-size: 32px
            width: 400px
        .shop-button
          width: 230px
          height: 40px
          text-transform: uppercase
          border: 1px solid white
          @media all and (min-width: $desktop-width-breakpoint)
            width: 320px
        @media all and (min-width: $desktop-width-breakpoint)
          padding: 102px 19px 90px 80px
          .heading
            font-size: 20px
          .text-wrapper
            align-items: flex-start
            .first-text
              font-size: 20px
            .second-text
              margin-top: 17px
              font-size: 32px
  .categories
    margin: 0 21px 40px
    text-transform: uppercase
    display: flex
    position: relative
    @media all and (min-width: $desktop-width-breakpoint)
      justify-content: center
    .without-children
      display: flex
      flex-direction: column
      margin: 0 11px
      .link
        margin: 0 0 5px
      a
        font-size: 16px
        @media all and (min-width: $desktop-width-breakpoint)
          font-size: 12px
      &.mobile
        margin: 0
    .with-children
      display: flex
      .taxon
        margin: 0 11px
    .target
      position: absolute
      bottom: -90px
      left: 150px
      @media all and (min-width: $desktop-width-breakpoint)
        left: 76%
        bottom: -60px
  #best-sellers
    .best-sellers
      background-color: #F8F6F3

  @import "how_it_works/call_to_action"
